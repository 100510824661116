
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInsightsModule, AppInsightsTelemtryItem, AUTH_CONFIG, AuthModule, TccUiModule, TitleRouteSyncerModule } from '@tcc/ui';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { API_BASE_URL, ApiClient } from './surveys/shared/api-client.service';
import { ConfigEnvironmentService } from './config.environment.service';

/** sets the cloud role in app insights telemetry */
export function aiTelemInitializer(env: AppInsightsTelemtryItem) {
  env.tags!['ai.cloud.role'] = 'TccSurveys Web Client';
}
// export function getAuthConfig() {
//   /* eslint-disable @typescript-eslint/naming-convention */
//   return {
//     client_id: 'cfe408c4-5724-4e6a-95e2-55b62c60ccfc',
//     redirect_uri: window.location.origin + '/auth',
//     silent_redirect_uri: window.location.origin + '/auth-silent-callback.html',
//     post_logout_redirect_uri: window.location.origin + '/auth/unauthorized',
//     resource: '48b0d4a9-ac50-44f1-910b-c22a80624031',
//   } as AuthConfig;
//   /* eslint-enable @typescript-eslint/naming-convention */
// }


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AppInsightsModule.forRoot({ instrumentationKey: environment.appInsightsId }, [aiTelemInitializer]),
    AuthModule.forRoot(),
    TccUiModule.forRoot(),
    TitleRouteSyncerModule.forRoot({ defaultTitle: '360° Feedback' })
  ],
  providers: [
    ApiClient,
    ConfigEnvironmentService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigEnvironmentService],
      useFactory: (configService: ConfigEnvironmentService) => () => configService.load(environment),
      multi: true,
    },
    {
      provide: API_BASE_URL,
      deps: [ConfigEnvironmentService, APP_INITIALIZER],
      useFactory: (configService: ConfigEnvironmentService) =>
        configService?.get('apiBaseUrl'),
    },
    {
      provide: AUTH_CONFIG,
      deps: [ConfigEnvironmentService, APP_INITIALIZER],
      useFactory: (configService: ConfigEnvironmentService) =>
        configService?.getAuthConfig()
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
