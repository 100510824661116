/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AuthConfig } from '@tcc/ui';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigEnvironmentService {

    private values: Configs = new Configs();
    constructor() { }

    load = async <T>(env: T): Promise<T> => {
        try {
                const response = fetch('./assets/configs.json');
                const configs: T = await (await response).json();
                for (const key in configs) {
                if (Object.prototype.hasOwnProperty.call(configs, key)) {
                    const targetProp = env[key];
                    if (targetProp !== undefined) {
                        env[key] = configs[key];

                        switch(key) {
                            case 'apiBaseUrl':
                                this.values.apiBaseUrl = configs[key];
                                break;
                            case 'appInsightsId':
                                this.values.appInsightsId = configs[key];
                                break;
                            case 'envName':
                                this.values.envName = configs[key];
                                break;
                            case 'clientId':
                                this.values.clientId = configs[key];
                                break;
                                case 'production':
                                this.values.production = configs[key];
                                break;
                        }
                    }
                }
            }
        }
        catch (e) {}
        return env;
    };

    // Gets a value of specified property in the configuration file
    get(key: any) {
        switch(key) {
            case 'apiBaseUrl':
                return this.values.apiBaseUrl;
                break;
            case 'appInsightsId':
                return this.values.appInsightsId;
                break;
            case 'envName':
                return this.values.envName;
                break;
            case 'clientId':
                return this.values.clientId;
                break;
            case 'production':
                return this.values.production;
                break;
        }
    }

    getAuthConfig(): AuthConfig {

        const authConfig = {
            clientId: this.values.clientId,
            logLevel: 1,
            redirect_uri: window.location.origin + '/auth',
            silent_redirect_uri: window.location.origin + '/auth-silent-callback.html',
            post_logout_redirect_uri: window.location.origin + '/auth/unauthorized',
            protectedResourceMap: new Map([
              [
                this.values.apiBaseUrl + '/*',
                ['48b0d4a9-ac50-44f1-910b-c22a80624031/user_impersonation']
              ]
            ])
          } as AuthConfig;

        return authConfig;
    }
}

// export function getAuthConfig() {
//   /* eslint-disable @typescript-eslint/naming-convention */
//   return {
//     client_id: 'cfe408c4-5724-4e6a-95e2-55b62c60ccfc',
//     redirect_uri: window.location.origin + '/auth',
//     silent_redirect_uri: window.location.origin + '/auth-silent-callback.html',
//     post_logout_redirect_uri: window.location.origin + '/auth/unauthorized',
//     resource: '48b0d4a9-ac50-44f1-910b-c22a80624031',
//   } as AuthConfig;
//   /* eslint-enable @typescript-eslint/naming-convention */
// }


export function configFactory(config: ConfigEnvironmentService) {
    return () => config.load(environment);
}

export class Configs {
    apiBaseUrl: any;
    appInsightsId: any;
    clientId: any;
    envName: any;
    production: any;
}
