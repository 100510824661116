<nav class="navbar navbar-fixed-top bg-gradient-collier text-white">
  <a class="navbar-brand">
    <img src="360-icon.png" />
    360&deg; Feedback
  </a>
  <tcc-user-status></tcc-user-status>
</nav>
<div class="contentPanel">
  <router-outlet></router-outlet>
</div>

<tcc-preloader *ngIf="state == 'preloading'">360&deg; Feedback</tcc-preloader>
