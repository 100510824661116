import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { first, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  state: 'preloading' | 'ready' = 'preloading';

  constructor(private router: Router) {

  }

  ngOnInit() {
    // take of preloader
    this.router.events
      .pipe(
        filter(x => x instanceof NavigationEnd),
        first(),
        tap(() => this.state = 'ready')
      )
      .subscribe();
  }
}
